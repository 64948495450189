( function( $, document, window ) {
    'use strict';

    $( function() {
    	const interval = 250;
    	const visibleHeight = 400;
    	const activeClass = 'sticky-header--is-active';
	    const $header = $( '.js-sticky-header' );
	    let didScroll = false;

	    $( window ).on( 'scroll', function() {
	    	didScroll = true;
	    } );

	    setInterval( function() {
	    	if ( didScroll ) {
	    		handleScroll();
	    		didScroll = false;
		    }
	    }, interval );

	    const handleScroll = () => {
		    let currentScrollTop = ( $( window ).scrollTop() || $( 'body' ).scrollTop() );

		    if ( currentScrollTop > visibleHeight ) {
			    if ( ! $header.hasClass( activeClass ) ) {
			    	$header.addClass( activeClass );
			    }
		    } else {
		    	if ( $header.hasClass( activeClass ) ) {
		    		$header.removeClass( activeClass );
			    }
		    }
	    };

	    $( window ).trigger( 'scroll' );
    } );
} )( jQuery, document, window );
