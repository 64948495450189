( function( $ ) {
	'use strict';

	$( function() {
		const $carousel = $( '.js-testimonial-carousel' );
		const dotsSel = '.js-testimonial-carousel-dots';
		const $item = $( '.js-testimonial-carousel-item' );
		const $title = $( '.js-testimonial-carousel-title' );

		const updateTitle = index => {
			index = ( index < 0 ) ? 0 : index;
			const currentItem = $item[index];

			if ( typeof currentItem === 'undefined' ) {
				return false;
			}

			if ( typeof $( currentItem ).data( 'title' ) !== 'undefined' ) {
				$title.show();
				$title.text( $( currentItem ).data( 'title' ) );

				if ( $title.text() === '' ) {
					$title.hide();
				}
			} else {
				$title.hide();
			}
		};

		$carousel.on( 'initialize.owl.carousel, changed.owl.carousel', function( event ) {
			updateTitle( event.page.index );
		} );

		$carousel.owlCarousel( {
			items: 1,
			dots: true,
			dotsContainer: dotsSel,
		} );

	} );
} )( jQuery );
