( function( $ ) {
    'use strict';

    $( function() {
	    const activeClass = 'is-active';

	    const $item = $( '.js-offcanvas-menu-item' );
	    const $trigger = $( '.js-offcanvas-menu-trigger' );
	    const $submenu = $( '.js-offcanvas-menu-submenu' );
	    const $back = $( '.js-site-offcanvas-back' );

	    $back.removeClass( activeClass );

    	// Add heading to each submenu
    	$submenu.each( function() {
    		const title = $( this ).closest( $item ).find( $trigger ).first().text().trim();
    		$( this ).prepend( `<div class="offcanvas-menu-submenu__title  js-offcanvas-menu-submenu-title">${title}</div>` );
	    } );

    	$trigger.on( 'click', function( event ) {
    		event.preventDefault();
		    $( this ).parent().find( $submenu ).first().addClass( activeClass );
		    $back.addClass( activeClass );
	    } );

	    $back.on( 'click', function() {
		    $submenu.filter( `.${activeClass}` ).last().removeClass( activeClass );

		    if ( $submenu.filter( `.${activeClass}` ).length === 0 ) {
		    	$back.removeClass( activeClass );
		    }
	    } );
    } );
} )( jQuery );
