( function( $, document ) {
    'use strict';

    $( function() {
    	const activeClass = 'site-search--is-active';
    	const $search = $( '.js-site-search' );
	    const $form = $( '.js-site-search-form' );
	    const $input = $( '.js-site-search-input' );
    	const $button = $( '.js-site-search-button' );
    	const $icon = $( '.js-site-search-icon' );

	    const closeSearch = () => {
		    if ( $search.hasClass( activeClass ) ) {
			    $search.removeClass( activeClass );
			    deregisterCloseHandler();
		    }
	    };

    	const deregisterCloseHandler = () => {
    		$search.off( 'click.close.search.songbird' );
	    };

    	const registerCloseHandler = () => {
		    $( document ).on( 'click.close.search.songbird touchstart.close.search.songbird', function( event ) {
			    const $target = $( event.target );

			    if ( ! $target.is( $search ) && ! $target.parents().is( $search ) && ! $target.is( $icon ) ) {
				    closeSearch();
			    }
		    } );

		    $search.on( 'focusout.close.search.songbird', function() {
		    	closeSearch();
		    } );
	    };

	    const registerOpenHandler = () => {
		    $search.on( 'click.open.search.songbird focusin.open.search.songbird', function( event ) {
		    	const $target = $( event.target );

		    	if ( $target.is( $input ) || $target.is( $button ) || $target.is( $icon ) ) {
				    $( this ).addClass( activeClass );
				    registerCloseHandler();
			    }
		    } );
	    };

	    const registerSubmitHandler = () => {
		    $form.on( 'submit', function( event ) {
		    	event.preventDefault();

		    	if ( $search.hasClass( activeClass ) && $input.val() !== '' ) {
		    		$( this ).off( 'submit' ).trigger( 'submit' );
			    }
		    } );
	    };

	    registerSubmitHandler();
	    registerOpenHandler();
    } );
} )( jQuery, document );
