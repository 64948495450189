( function( $ ) {
    'use strict';

    $( function() {
	    const activeClass = 'is-active';
	    const duration = 400;
	    const $item = $( '.js-accordion-item' );
	    const $trigger = $( '.js-accordion-trigger' );
	    const $body = $( '.js-accordion-body' );

	    $trigger.on( 'click', function() {
	    	const isActive = $( this ).closest( $item ).hasClass( activeClass );

		    // remove active class from all other items
		    $item.each( function() {
			    if ( $( this ).hasClass( activeClass ) ) {
	    			$( this ).removeClass( activeClass ).find( $body ).slideUp();
	    			$( this ).find( $trigger ).attr( 'aria-expanded', false );
			    }
		    } );

		    // if the current item is not active, make it active
		    if ( ! isActive ) {
			    $( this ).closest( $item ).addClass( activeClass ).find( $body ).slideToggle( duration, function() {
				    $( this ).closest( $item ).find( $trigger ).attr( 'aria-expanded', true );
			    } );
		    }
	    } );
    } );
} )( jQuery );
