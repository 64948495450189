( function( $ ) {
    'use strict';

    $( function() {
	    const activeClass = 'site-offcanvas--is-active';

	    const $trigger = $( '.js-site-offcanvas-trigger' );
	    const $body = $( '.js-site-offcanvas' );
	    const $close = $( '.js-site-offcanvas-close' );

	    $trigger.on( 'click', function() {
	    	$body.addClass( activeClass );
	    } );

	    $close.on( 'click', function() {
	    	$body.removeClass( activeClass );
	    } );
    } );
} )( jQuery );
