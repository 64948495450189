( function( $ ) {
    'use strict';

    $( function() {
	    const activeClass = 'is-active';
	    const duration = 400;
	    const $item = $( '.js-expander-item' );
	    const $trigger = $( '.js-expander-trigger' );
	    const $body = $( '.js-expander-body' );

        $trigger.on( 'click', function() {
	        $( this ).closest( $item ).toggleClass( activeClass ).find( $body ).slideToggle( duration, function() {
	        	const $parentItem = $( this ).closest( $item );
		        const $button = $parentItem.find( $trigger );

	        	if ( $parentItem.hasClass( activeClass ) ) {
	        		$button.attr( 'aria-expanded', true );
		        } else {
			        $button.attr( 'aria-expanded', false );
		        }
	        } );
        } );
    } );
} )( jQuery );
