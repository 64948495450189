( function( $ ) {
	'use strict';

	$( function() {
		const $carousel = $( '.js-image-carousel' );
		const $prevButton = $( '.js-image-carousel-button-prev' );
		const $nextButton = $( '.js-image-carousel-button-next' );

		$carousel.owlCarousel( {
			items: 1,
			dots: false,
			nav: false,
		} );

		$prevButton.on( 'click', function() {
			$carousel.trigger( 'prev.owl.carousel' );
		} );

		$nextButton.on( 'click', function() {
			$carousel.trigger( 'next.owl.carousel' );
		} );
	} );
} )( jQuery );
