( function( $ ) {
    'use strict';

    $( function() {
        const $carousel = $( '.js-video-carousel' );
        const $prevButton = $( '.js-video-carousel-button-prev' );
        const $nextButton = $( '.js-video-carousel-button-next' );

        $carousel.owlCarousel( {
            items: 1,
            dots: false,
            nav: false,
        } );

        $prevButton.on( 'click', function() {
            $carousel.trigger( 'prev.owl.carousel' );
        } );

        $nextButton.on( 'click', function() {
            $carousel.trigger( 'next.owl.carousel' );
        } );
    } );
} )( jQuery );
