( function( $ ) {
	'use strict';

	$( function() {
		const $carousel = $( '.js-latest-posts-carousel' );
		const carouselSettings = {
			items: 1,
			dots: true,
			nav: false,
			responsive: {
				580: {
					items: 2,
				},
				768: {
					items: 3,
				}
			}
		};
		let resizeTimer = null;
		let timeout = 500;

		let init = () => {
			if ( window.matchMedia( `(max-width: ${( 767 / 16 ).toFixed( 8 )}em` ).matches ) {
				$carousel.owlCarousel( carouselSettings );
			} else {
				$carousel.trigger( 'destroy.owl.carousel' );
			}
		};

		$( window ).on( 'resize', function() {
			clearTimeout( resizeTimer );
			resizeTimer = setTimeout( init, timeout );
		} );

		init();
	} );
} )( jQuery );
